import styles from "./Header.module.scss";
import LinkImage from "../../common/linkImage/LinkImage";
import BubbleHead from "../../common/bubbleHead/BubbleHead";

const headerImage = require("./../../assets/logo-en.png");
const headImage = require("./../../assets/20240308_110606.jpg");
const cartImage = require("./../../assets/UtilsIcons/Group 69.png");

const Header = () => {
  return (
    <div className={`${styles["header-container"]} ${styles["bottom-line"]}`}>
      <LinkImage src={headerImage} alt="VelexFarm" route={"home"} />
      <div className={styles["bubble-cointainer"]}>
        <BubbleHead
          src={cartImage}
          num={23}
          customClass={styles["img-cart"]}
          alt="head"
        />
        <BubbleHead src={headImage} alt="head" />
      </div>
    </div>
  );
};
export default Header;
