import styles from "./Carousel.module.scss";
import { useEffect, useState } from "react";

const Carousel = ({ slides, isReversed }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === slides.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000); // Change slide every 5 seconds

    return () => clearInterval(timer);
  }, [slides.length]);

  const goToSlide = (index) => {
    setCurrentIndex(index);
  };

  return (
    <div className={styles["carousel"]}>
      <div className={styles["slides"]}>
        {slides.map((slide, index) => (
          <div
            key={index}
            className={`${styles["slide"]} ${
              index === currentIndex ? styles["active"] : ""
            }`}
          >
            {slide}
          </div>
        ))}
      </div>
      <div className={styles["dots"]}>
        {slides.map((_, index) => (
          <span
            key={index}
            className={`${styles["dot"]} 
            ${isReversed && styles["reversed"]}
            ${index === currentIndex ? styles["active"] : ""}`}
            onClick={() => goToSlide(index)}
          ></span>
        ))}
      </div>
    </div>
  );
};

export default Carousel;
