import { useEffect, useState } from "react";
import dataVariables from "../../../helpers/data/DataVariables";
import styles from "./NewOrderModal.module.scss";
import SingleSelectDropdown from "./../../../common/singleSelectDropdown/SingleSelectDropdown";
import getCurrentDateFormatted from "../../../helpers/dateHelper/GetCurrentDateFormatted";
import calculateTotal from "../../../helpers/functions/CalculateTotal";
import generateId from "../../../helpers/functions/GeneratId";
import NumericImput from "../../../composites/table/tableRow/tableItem/numericImput/NumericImput";

const NewOrderModal = ({ setAction }) => {
  const [products, setProducts] = useState(dataVariables.productList);
  const [product, setProduct] = useState();
  const [quantity, setQuantity] = useState(1);

  const AddressList = [
    { id: 1, value: "Addressa 1" },
    { id: 2, value: "Addressa 2" },
    { id: 3, value: "Addressa 3" },
  ];
  const [address, setAddress] = useState(AddressList[0]);

  const updateOrder = () => {
    setAction({
      Id: generateId(),
      Product: product,
      Name: product.name,
      Date: getCurrentDateFormatted(),
      Destination: address.value,
      type: product.type,
      Status: "Poslato",
      UnitPrice: product.unitPrice,
      Quantity: quantity,
      Discount: product.discount ? product.discount.value : "-",
      Total: calculateTotal(
        product.unitPrice,
        product.discount ? product.discount.value : "-",
        quantity
      ),
    });
  };

  useEffect(() => {
    if (product) updateOrder();
  }, [address, product, quantity]);

  return (
    <div className={styles["new-order-modal-conatiner"]}>
      <SingleSelectDropdown
        options={dataVariables.productList.map((x) => {
          return { id: x.id, value: x.name };
        })}
        onSelect={(x) => {
          setProduct(products.find((y) => y.id == x.id));
        }}
        customStyles={styles["filter-new-orders-select"]}
        placeholder="Proizvod"
      />
      <div className={styles["new-order-modal-info-conatiner"]}>
        <div className={styles["new-order-modal-info-top"]}>
          <div>
            <span>Šifra Artikla :</span>
            <span className={styles["new-order-modal-info-value"]}>
              {product ? product.id : "-"}
            </span>
          </div>
          <div>
            <span>Cena :</span>
            <span className={styles["new-order-modal-info-value"]}>
              {product ? `${product.unitPrice} RSD` : "-"}{" "}
            </span>
          </div>
          <div>
            <span>Popust :</span>
            <span className={styles["new-order-modal-info-value"]}>
              {product
                ? product.discount
                  ? product.discount.value
                  : "-"
                : "-"}
            </span>
          </div>

          <div>
            <span>PDV :</span>
            <span className={styles["new-order-modal-info-value"]}>
              {product ? "10%" : "-"}
            </span>
          </div>
          <div>
            <span>Naziv proizvođača :</span>
            <span className={styles["new-order-modal-info-value"]}>
              {product ? product.mainacturer : "-"}
            </span>
          </div>
          <div>
            <span>Status :</span>
            <span className={styles["new-order-modal-info-value"]}>
              {product ? "ODOBRENO" : "-"}
            </span>
          </div>
        </div>
        <div className={styles["new-order-modal-info-bottom"]}>
          <div>
            <span>Adresa za isporuku :</span>
            <span>
              <SingleSelectDropdown
                isDisabled={!product}
                options={AddressList}
                onSelect={(e) => {
                  setAddress(e);
                  updateOrder();
                }}
                customStyles={styles["filter-address-select"]}
                placeholder="Adresa"
              />
            </span>
          </div>
          <div>
            <span>Količina :</span>
            <span>
              <NumericImput data={quantity} action={setQuantity} />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewOrderModal;
