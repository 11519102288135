const getCurrentDateFormatted = (additionalDays) => {
  const date = new Date();

  // Get month, day, and year
  if (additionalDays) {
    date.setDate(date.getDate() + additionalDays);
  }
  let month = date.getMonth() + 1; // Months are zero-based, so add 1
  let day = date.getDate();
  const year = date.getFullYear();

  // Format month and day to two digits
  if (month < 10) {
    month = "0" + month;
  }
  if (day < 10) {
    day = "0" + day;
  }

  // Create formatted date string
  const formattedDate = `${month}.${day}.${year}`;
  return formattedDate;
};
export default getCurrentDateFormatted;
