const calculateTotal = (unitPrice, discount, quantity) => {
  let total = 0;
  if (quantity > 0) {
    switch (discount) {
      case "5%": {
        total = unitPrice * quantity * 0.95;
        break;
      }
      case "10%": {
        total = unitPrice * quantity * 0.9;
        break;
      }
      case "20%": {
        total = unitPrice * quantity * 0.8;
        break;
      }
      case "3+1": {
        total = unitPrice * (quantity - Math.floor(quantity / 4));
        break;
      }
      case "2+1": {
        total = unitPrice * (quantity - Math.floor(quantity / 3));
        break;
      }
      default: {
        total = unitPrice * quantity;
        break;
      }
    }
    return total;
  }
};
export default calculateTotal;
