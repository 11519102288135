import styles from "./TableItem.mode.scss";
import Discount from "./discount/Discount";
import SingleSelect from "./singleSelect/SingleSelect";
import NumericImput from "./numericImput/NumericImput";
import Download from "./downloadIcon/DownloadIcon";
import DeleteIcon from "./deleteIcon/DeleteIcon";

const TableItem = ({ data, itemType, calculationAction }) => {
  switch (itemType.type) {
    case "discount":
      return <td>{data ? <Discount data={data} /> : "-"}</td>;
    case "singleSelect":
      return (
        <td>
          {data && itemType.options ? (
            <SingleSelect data={data} options={itemType.options} />
          ) : (
            "-"
          )}
        </td>
      );
    case "numericImput":
      return (
        <td>
          <NumericImput data={data} action={calculationAction} />
        </td>
      );

    case "download":
      return (
        <td>
          <Download />
        </td>
      );

    case "delete":
      return (
        <td>
          <DeleteIcon />
        </td>
      );
      return <td>{data} RSD</td>;
    default:
      return <td>{data}</td>;
  }
};
export default TableItem;
