import styles from "./NavBar.module.scss";
import NavLink from "./../../common/navLink/NavLink";
import dataVariables from "./../../helpers/data/DataVariables";
import { useState } from "react";

const NavBar = () => {
  const [headerVariables, setHeaderVariables] = useState(
    dataVariables.headerVariables
  );
  var changeActive = (name) => {
    var newHeaderVariables = dataVariables.headerVariables.map((x) => {
      var isActive = x.name == name ? true : false;
      return { ...x, isActive: isActive };
    });
    setHeaderVariables(newHeaderVariables);
  };
  return (
    <div className={styles["navbar-container"]}>
      {headerVariables.map((x, index) => {
        return (
          <NavLink
            key={index}
            action={() => changeActive(x.name)}
            name={x.name}
            route={x.route}
            icon={x.icon}
            isActive={x.isActive}
          />
        );
      })}
    </div>
  );
};
export default NavBar;
