import { ReactComponent as HomeSvg } from "../../assets/NavIcons/home2.svg";
// import { ReactComponent as orderSvg } from "../../assets/NavIcons/naru.svg";
// import { ReactComponent as HomeSvg } from "../../assets/NavIcons/home1.svg";
// import { ReactComponent as HomeSvg } from "../../assets/NavIcons/home1.svg";
// import { ReactComponent as HomeSvg } from "../../assets/NavIcons/home1.svg";
// import { ReactComponent as HomeSvg } from "../../assets/NavIcons/home1.svg";
const headerVariables = [
  {
    name: "Home",
    route: "home",
    svg: HomeSvg,
    icon: "icon-home",
    isActive: true,
  },
  {
    name: "Pregled Porudžbina",
    route: "orderOverview",
    svg: HomeSvg,
    icon: "icon-order",
    isActive: false,
  },
  {
    name: "Reklamacije",
    route: "complaints",
    svg: HomeSvg,
    icon: "icon-complaints",
    isActive: false,
  },
  {
    name: "Finansijski Izveštaj",
    route: "financialReport",
    svg: HomeSvg,
    icon: "icon-finRep",
    isActive: false,
  },
  {
    name: "Proizvodi",
    route: "products",
    svg: HomeSvg,
    icon: "icon-products",
    isActive: false,
  },
  {
    name: "Promocije",
    route: "promotion",
    svg: HomeSvg,
    icon: "icon-promotion",
    isActive: false,
  },
  {
    name: "Podešavanja",
    route: "settings",
    scg: HomeSvg,
    icon: "icon-settings",
    isActive: false,
  },
];
export default headerVariables;
