import orderTypes from "./OrderTypes";

const orderTableHeading = [
  { title: "Šifra", field: "Id", type: "text" },
  { title: "Naziv", field: "Name", type: "text" },
  { title: "Datum", field: "Date", type: "text" },
  { title: "Mesto dostave", field: "Destination", type: "text" },
  {
    title: "Kategorija",
    field: "type",
    type: "singleSelect",
    options: orderTypes,
  },
  { title: "Količina", field: "Quantity", type: "numericImput" },
  { title: "Jedinična cena", field: "UnitPrice", type: "numeric" },
  { title: "Kasa Konto", field: "Konto", type: "text" },
  { title: "Popust", field: "Discount", type: "discount" },
  { title: "Iznos", field: "Total", type: "price" },
];
export default orderTableHeading;
