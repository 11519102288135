import styles from "./NavLink.module.scss";
import { useNavigate } from "react-router-dom";

const NavLink = ({
  name,
  route,
  icon,
  action,
  isActive = false,
  isShortened,
}) => {
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate(route);
  };

  const iconStyles = `${styles["nav-icon"]} ${styles[icon]} ${
    isActive && styles["icon-selected"]
  }`;
  const orangeBlockStyles = `${styles["nav-link-sufix"]} ${
    isActive && styles["nav-link-sufix-active"]
  }`;
  return (
    <div
      className={styles["nav-link-container"]}
      onClick={() => {
        action();
        handleNavigation();
      }}
    >
      <span className={orangeBlockStyles}>&nbsp;</span>
      <span className={styles["nav-link-item"]}>
        <span className={iconStyles}> &nbsp;</span>
        {!isShortened && <span>{name ?? "link"}</span>}
      </span>
    </div>
  );
};
export default NavLink;
