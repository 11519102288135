import orderList from "../../helpers/data/order/OrderList";
import productList from "../../helpers/data/product/ProductList";
import getCurrentDateFormatted from "../../helpers/dateHelper/GetCurrentDateFormatted";
import calculateTotal from "../../helpers/functions/CalculateTotal";

function getRandomNumber() {
  const numbers = [2, 5, 8];
  const randomIndex = Math.floor(Math.random() * numbers.length);
  return numbers[randomIndex];
}

const OrderService = {
  getOrders: () => {
    return orderList.map((x) => {
      return {
        Id: x.Id,
        Product: productList[3],
        Name: x.Product.name,
        Date: getCurrentDateFormatted(),
        Destination: "Address 3",
        type: x.Product.type,
        Status: "Poslato",
        Konto: ` ${getRandomNumber()}%`,
        UnitPrice: x.Product.unitPrice,
        Quantity: x.Quantity,
        Discount: x.DiscountType ? x.DiscountType.value : "-",
        Total: calculateTotal(
          x.Product.unitPrice,
          x.DiscountType ? x.DiscountType.value : "-",
          x.Quantity
        ),
      };
    });
  },
};
export default OrderService;
