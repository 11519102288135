import headerVariables from "./HeaderVariables";
import orderTableHeading from "./order/OrderTableHeading";
import getEmptyOrder from "./order/EmptyOrder";
import orderPriceTypes from "./order/OrderPriceTypes";
import orderTypes from "./order/OrderTypes";
import productList from "./product/ProductList";
import transactionTableHeading from "./transaction/TransactionTableHeading";
import invoiceTableHeading from "./invoice/InvoiceTableHeading";
import orderInvoiceTableHeading from "./order/OrderInvoiceTableHeading";

const dataVariables = {
  //routes
  headerVariables,
  //table headings
  orderTableHeading,
  transactionTableHeading,
  invoiceTableHeading,
  orderInvoiceTableHeading,
  //orders
  getEmptyOrder: getEmptyOrder,
  orderTypes,
  //price
  orderPriceTypes,
  //products
  productList,
};
export default dataVariables;
