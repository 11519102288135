import styles from "./App.module.scss";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PageLayout from "./composites/pageLayout/PageLayout";
import Home from "./pages/home/Home";
import OrderOverview from "./pages/orderOverview/OrderOverview";

function App() {
  return (
    <div className={styles["App"]}>
      <Router>
        <PageLayout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/orderOverview" element={<OrderOverview />} />
          </Routes>
        </PageLayout>
      </Router>
    </div>
  );
}

export default App;
