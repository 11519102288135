import styles from "./InputSearchFilter.module.scss";
import Input from "./../../common/input/Input";
import { useEffect, useState } from "react";

const InputSearchFilter = ({ action, customStyles, placeholder }) => {
  const [displayValue, setDisplayValue] = useState("");
  const filter = (e) => {
    setDisplayValue(e);
    action(e);
  };
  return (
    <div>
      <Input
        customStyles={customStyles}
        value={displayValue}
        action={filter}
        placeholder={placeholder}
      />
    </div>
  );
};
export default InputSearchFilter;
//value, type, action,
