import getCurrentDateFormatted from "../../dateHelper/GetCurrentDateFormatted";
import discountList from "../discounts/DiscountList";
import orderTypes from "../order/OrderTypes";

const salofalk = require("./../../../assets/Rectangle 121.png");
const paracetamol = require("./../../../assets/Rectangle 158.png");
const pressing = require("./../../../assets/Rectangle 159.png");
const magma = require("./../../../assets/Rectangle 160.png");

const discountProductList = [
  {
    id: "2",
    name: "Paracetamol FTBL 30x100mg",
    type: orderTypes[2],
    discount: discountList[3].value,
    unitPrice: 100,
    mainacturer: "Galenika",
    fromDate: getCurrentDateFormatted(),
    toDate: getCurrentDateFormatted(5),
    exparationDate: getCurrentDateFormatted(20),

    picture: paracetamol,
  },
  {
    id: "3",
    name: "Pressing 1000mg",
    type: orderTypes[2],
    discount: discountList[1].value,
    unitPrice: 100,
    mainacturer: "Uraf",
    fromDate: getCurrentDateFormatted(),
    toDate: getCurrentDateFormatted(1),
    exparationDate: getCurrentDateFormatted(20),
    picture: pressing,
  },
  {
    id: "4",
    name: "Magma ",
    type: orderTypes[2],
    discount: discountList[3].value,
    unitPrice: 100,
    mainacturer: "Jugomedia",
    fromDate: getCurrentDateFormatted(),
    toDate: getCurrentDateFormatted(2),
    exparationDate: getCurrentDateFormatted(20),
    picture: magma,
  },
];
export default discountProductList;
