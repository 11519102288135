import styles from "./OrderOverview.module.scss";
import Table from "./../../composites/table/Table";
import dataVariables from "./../../helpers/data/DataVariables";
import Modal from "./../../composites/modal/Modal";
import InputSearchFilter from "./../../composites/inputSearchFilter/InputSearchFilter";
import SingleSelectDropdown from "./../../common/singleSelectDropdown/SingleSelectDropdown";
import { useEffect, useState } from "react";
import OrderService from "../../services/order/OrderService";
import NewOrderModal from "./newOrderModal/NewOrderModal";
import getEmptyOrder from "../../helpers/data/order/EmptyOrder";
import generateId from "../../helpers/functions/GeneratId";
import getCurrentDateFormatted from "../../helpers/dateHelper/GetCurrentDateFormatted";
import Button from "../../common/button/Button";

const OrderOverview = () => {
  const [orders, setOrders] = useState(OrderService.getOrders());
  const [filteredOrders, setFilteredOrders] = useState(orders);
  const [newOrder, setNewOrder] = useState();

  const addNewOrder = () => {
    setOrders([...orders, newOrder]);
    setFilteredOrders([...orders, newOrder]);
  };

  const calculationAction = (item) => {
    const newOrders = orders.map((order) => {
      if (order.Id === item.Id) {
        return {
          ...item,
        };
      }
      return order;
    });
    setOrders(newOrders);

    filterOrders(newOrders);
  };

  //FILTERS
  const [filteredName, setFilteredName] = useState("");
  const [filteredId, setFilteredId] = useState("");
  const [filteredPrice, setFilteredPrice] = useState("");
  const [filteredType, setFilteredType] = useState(null);

  const filterOrders = (newOrders) => {
    let filtered = [...newOrders];

    if (filteredId) {
      filtered = filtered.filter((x) =>
        x.Id.toLowerCase().includes(filteredId.toLowerCase())
      );
    }

    if (filteredName) {
      filtered = filtered.filter((x) =>
        x.Name.toLowerCase().includes(filteredName.toLowerCase())
      );
    }

    if (filteredType) {
      filtered = filtered.filter((x) => x.type.id == filteredType.id);
    }
    if (filteredPrice) {
      switch (filteredPrice.id) {
        case dataVariables.orderPriceTypes[1].id:
          filtered = filtered.filter((x) => {
            return !x.Discount;
          });
          break;
        case dataVariables.orderPriceTypes[2].id:
          filtered = filtered.filter((x) => {
            return !!x.Discount;
          });
          break;
        default:
          break;
      }
    }

    setFilteredOrders(filtered);
  };

  useEffect(() => {
    filterOrders(orders);
  }, [filteredId, filteredName, filteredType, filteredPrice]);
  const modalContent = <NewOrderModal setAction={setNewOrder} />;
  //MODAL
  const modal = (
    <Modal
      buttonName="+ Dodaj"
      submitButtonTitle="Dodaj"
      buttonClass={styles["modal-button-add"]}
      children={modalContent}
      onSave={addNewOrder}
      onOpen={() => {
        setNewOrder(getEmptyOrder());
      }}
    />
  );

  const generateOrderInvoice = (n) => {
    const status = ["U prirpemi", "Potrđeno", "Isporučeno"];
    const retval = [];
    for (let index = 0; index < n; index++) {
      retval.push({
        id: `${generateId()}`,
        date: getCurrentDateFormatted(),
        status: status[Math.floor(Math.random() * 3)],
        type: "Razno",
      });
    }
    return retval;
  };
  const [orderInvoice, setOrderInvoice] = useState(generateOrderInvoice(10));

  return (
    <div className={styles["order-overview-container"]}>
      <div className={styles["order-overview-table-filter"]}>
        <InputSearchFilter
          action={setFilteredId}
          customStyles={styles["filter-orders-input"]}
          placeholder="Šifra"
        />
        <InputSearchFilter
          action={setFilteredName}
          customStyles={styles["filter-orders-input"]}
          placeholder="Naziv"
        />
        <SingleSelectDropdown
          options={dataVariables.orderTypes}
          onSelect={setFilteredType}
          clearOptionText="Sve"
          customStyles={styles["filter-orders-select"]}
          placeholder="Kategorija"
        />
        <SingleSelectDropdown
          options={dataVariables.orderPriceTypes}
          onSelect={setFilteredPrice}
          defaultValue={dataVariables.orderPriceTypes[0].value}
          customStyles={styles["filter-orders-select"]}
          placeholder="Plaćanja"
        />
      </div>
      <div className={styles["order-overview-table-legend"]}>
        {modal}
        <Button
          name={"Pošalji"}
          classes={`${styles["order-overview-table-legend-button"]}`}
        />
      </div>
      <div className={styles["order-overview-table-container"]}>
        <Table
          headingList={dataVariables.orderTableHeading}
          tabeData={filteredOrders}
          calculationAction={calculationAction}
          isSaveDisabled={newOrder?.Quantity > 0}
        />
      </div>
      <div className={styles["order-overview-table-container"]}>
        <Table
          headingList={dataVariables.orderInvoiceTableHeading}
          tabeData={orderInvoice}
          calculationAction={calculationAction}
          isSaveDisabled={newOrder?.Quantity > 0}
        />
      </div>
    </div>
  );
};
export default OrderOverview;
