import getCurrentDateFormatted from "../../dateHelper/GetCurrentDateFormatted";
import orderTypes from "./OrderTypes";

const getEmptyOrder = () => {
  return {
    Id: "",
    Name: "",
    Date: getCurrentDateFormatted(),
    type: "",
    Destination: "",
    Status: "",
    Quantity: 0,
    UnitPrice: 0,
    Discount: 0,
    Total: 0,
    Product: "",
    DiscountType: "",
  };
};
export default getEmptyOrder;
