import styles from "./Modal.module.scss";
import Button from "../../common/button/Button";
import { useEffect, useState } from "react";

const Modal = ({
  buttonName = "modal",
  buttonClass = "",
  title,
  onOpen = () => {
    console.log("//TODO needs implementation for Save");
  },
  isSaveDisabled = false,
  submitButtonTitle = "",
  cancelButtonTitle = "",
  children,
  onSave = () => {
    console.log("//TODO needs implementation for Save");
  },
  onClose = () => {
    console.log("//TODO needs implementation for Close");
  },
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleModal = () => {
    setIsOpen(!isOpen);
    onClose();
  };
  useEffect(() => {
    onOpen();
  }, []);

  return (
    <div>
      <Button classes={buttonClass} name={buttonName} action={toggleModal} />

      {isOpen && (
        <div className={styles["modal"]}>
          <div onClick={toggleModal} className={styles["overlay"]}></div>
          <div className={styles["modal-content"]}>
            <div className={styles["modal-title"]} onClick={toggleModal}>
              {title}
            </div>
            {children}
            <div className={styles["subbmit-button"]}>
              {cancelButtonTitle && (
                <Button
                  action={() => {
                    setIsOpen(false);
                  }}
                  name={cancelButtonTitle}
                ></Button>
              )}
              {submitButtonTitle && (
                <Button
                  classes={styles["save-button"]}
                  isActive={!isSaveDisabled}
                  action={() => {
                    onSave();
                    setIsOpen(false);
                  }}
                  name={submitButtonTitle}
                ></Button>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Modal;
