import styles from "./Promotionbox.module.scss";

const Promotionbox = ({
  title,
  maufacturer,
  fromDate,
  toDate,
  exparationDate,
  picture,
  discount,
  isPromotion = false,
}) => {
  return (
    <div
      className={`${styles["promotion-box-container"]}  ${
        isPromotion && styles["slide"]
      }`}
    >
      <div className={styles["promotion-box-image-container"]}>
        <img src={picture} alt={title} />
        <span className={styles["promotion-discount"]}>{discount}</span>
      </div>

      <div
        className={`${styles["promotion-box-text-container"]} ${
          isPromotion && styles["slide"]
        }`}
      >
        <h3>{title}</h3>
        <span className={styles["promotion-value"]}>{maufacturer}</span>
        <div>
          <div>
            <span> Akcija Traje:</span>
          </div>
          <div>
            <span className={styles["promotion-value"]}>
              {fromDate}-{toDate}
            </span>
          </div>
        </div>
        {!isPromotion && (
          <div>
            <span> Rok upotrebe :</span>

            <span className={styles["promotion-value"]}>{exparationDate}</span>
          </div>
        )}
      </div>
    </div>
  );
};
export default Promotionbox;
