import { useEffect } from "react";
import Carousel from "../../composites/carousel/Carousel";
import dataVariables from "../../helpers/data/DataVariables";
import generateId from "../../helpers/functions/GeneratId";
import Table from "./../../composites/table/Table";
import styles from "./Home.module.scss";
import discountProductList from "../../helpers/data/product/DiscountProductList";
import Promotionbox from "../../composites/promotionbox/Promotionbox";
import Icon from "./../../common/icon/Icon";
import getCurrentDateFormatted from "../../helpers/dateHelper/GetCurrentDateFormatted";
const refresh = require("./../../assets/Union.png");
const nextLine = require("./../../assets/send.png");

const Home = () => {
  const status = ["U prirpemi", "Potrđeno", "Isporučeno"];

  const carouselItems = [
    <div className={styles["carousel-baner-item"]}>
      Poručivanje do 13h roba stiže do 15h
    </div>,
    <div className={styles["carousel-baner-item"]}>
      Poručivanje do 17h, stiže sutra ujutru
    </div>,
  ];
  useEffect(() => {}, []);

  const generateInvoices = (n) => {
    const status = ["U prirpemi", "Potrđeno", "Isporučeno"];
    const retval = [];
    for (let index = 0; index < n; index++) {
      retval.push({
        code: `${generateId()}`,
        status: status[Math.floor(Math.random() * 3)],
        get: "stuff",
      });
    }
    return retval;
  };

  const generateTransactions = (n) => {
    const status = ["U prirpemi", "Potrđeno", "Isporučeno"];
    const retval = [];
    for (let index = 0; index < n; index++) {
      retval.push({
        date: getCurrentDateFormatted(),
        destination: `Apoteka ${Math.floor(Math.random() * 3) + 1}`,
        status: status[Math.floor(Math.random() * 3)],
        total: `${(Math.floor(Math.random() * 100) + 1) * 1000}`,
      });
    }
    return retval;
  };
  const invoiceList = generateInvoices(12);

  const transactionsList = generateTransactions(12);

  return (
    <div>
      <div className={styles["home-carousel-container"]}>
        <Carousel slides={carouselItems} />
        <div className={styles["home-tables-container"]}>
          <div className={`${styles["home-table-wraper-left"]}`}>
            <div className={styles["section-heading"]}>
              <div className={styles["section-title"]}>PORUDŽBINE</div>
              <div className={styles["section-icons"]}>
                <Icon path={refresh} />
                <Icon path={nextLine} />
              </div>
            </div>
            <Table
              headingList={dataVariables.transactionTableHeading}
              tabeData={transactionsList}
            />
          </div>
          <div className={`${styles["home-table-wraper-right"]}`}>
            <div className={styles["section-heading"]}>
              <div className={styles["section-title"]}>FAKTURE</div>
              <div className={styles["section-icons"]}>
                <Icon path={nextLine} />
              </div>
            </div>
            <Table
              headingList={dataVariables.invoiceTableHeading}
              tabeData={invoiceList}
            />
          </div>
        </div>
        <div className={styles["promotion-container"]}>
          <div className={styles["section-heading"]}>
            <div className={`${styles["section-title"]}`}>PROMOCIJE</div>
            <div className={styles["section-icons"]}>
              <Icon path={refresh} />
            </div>
          </div>

          <div className={styles["promotion-row-container"]}>
            <div className={styles["promotion-row"]}>
              {discountProductList.map((x) => {
                return (
                  <div className={styles["promotion-row-item-wraper"]}>
                    <Promotionbox
                      customStyles={styles["home-promotion-box"]}
                      title={x.name}
                      maufacturer={x.mainacturer}
                      fromDate={x.fromDate}
                      toDate={x.toDate}
                      exparationDate={x.exparationDate}
                      picture={x.picture}
                      discount={x.discount}
                    />
                  </div>
                );
              })}
            </div>

            <div className={styles["promotion-row"]}>
              {discountProductList.map((x) => {
                return (
                  <div className={styles["promotion-row-item-wraper"]}>
                    <Promotionbox
                      customStyles={styles["home-promotion-box"]}
                      title={x.name}
                      maufacturer={x.mainacturer}
                      fromDate={x.fromDate}
                      toDate={x.toDate}
                      exparationDate={x.exparationDate}
                      picture={x.picture}
                      discount={x.discount}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Home;
