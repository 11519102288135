import styles from "./Icon.module.scss";

const Icon = ({ path }) => {
  return (
    <div className={styles["icon-container"]}>
      <img src={path} alt="img" />
    </div>
  );
};
export default Icon;
