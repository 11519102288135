import { useNavigate } from "react-router-dom";
import styles from "./LinkImage.module.scss";

const LinkImage = ({ src, alt, route }) => {
  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate(route);
  };
  return (
    <img
      className={styles["link-img"]}
      src={src}
      alt={alt}
      onClick={handleNavigation}
    />
  );
};
export default LinkImage;
