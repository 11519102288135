import styles from "./InfoBarData.module.scss";

const InfoBarData = ({ data }) => {
  return (
    <div className={styles["info-bar-data-container"]}>
      {data.map((x) => {
        return (
          <div className={styles["info-bar-data-item"]}>
            <div className={styles["info-bar-data-title"]}>{x.title}</div>
            <div className={styles["info-bar-data-value"]}>{x.value}</div>
          </div>
        );
      })}
    </div>
  );
};
export default InfoBarData;
