import styles from "./Button.module.scss";

const Button = ({
  name,
  action,
  classes = "",
  isActive = true,
  isHidden = false,
}) => {
  const customtyles = `${classes}`;
  return (
    <button
      type="button"
      className={customtyles}
      onClick={() => action()}
      disabled={!isActive}
    >
      {name}
    </button>
  );
};
export default Button;
