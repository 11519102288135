import styles from "./PageLayout.module.scss";
import Header from "../header/Header";
import NavBar from "../navBar/NavBar";
import InfoBar from "../infoBar/InfoBar";

const PageLayout = ({ children }) => {
  return (
    <div>
      <Header />
      <div className={styles["layout-content"]}>
        <NavBar />
        <div className={styles["layout-main-content"]}>{children}</div>
        <InfoBar />
      </div>
    </div>
  );
};

export default PageLayout;
