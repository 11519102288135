import styles from "./InfoBar.module.scss";
import DonutChart from "./../donutChart/DonutChart";
import InfoBarData from "./infoBarData/InfoBarData";
import discountProductList from "./../../helpers/data/product/DiscountProductList";
import Promotionbox from "../promotionbox/Promotionbox";
import Carousel from "../carousel/Carousel";
const informationImg = require("./../../assets/obavestenje.png");

const InfoBar = () => {
  const textData = [
    { title: "Datum učitavanja", value: "20.05.2024." },
    { title: "Kredit limit", value: "3.000.000" },
    { title: "Iskorišćeni limit", value: "-147.036,35" },
    { title: "Raspoloživi iznos", value: "3.147.063,35" },
  ];

  const promotions = discountProductList.map((x) => {
    return (
      <div className={styles["promotion-row-item-wraper"]}>
        <Promotionbox
          customStyles={styles["home-promotion-box"]}
          title={x.name}
          maufacturer={x.mainacturer}
          fromDate={x.fromDate}
          toDate={x.toDate}
          exparationDate={x.exparationDate}
          picture={x.picture}
          discount={x.discount}
          isPromotion={true}
        />
      </div>
    );
  });

  return (
    <div className={styles["infobar-container"]}>
      <DonutChart />
      <InfoBarData data={textData} />
      <div>
        <div className={styles["promotion-title"]}>Promocije</div>
        <Carousel slides={promotions} isReversed={true} />
      </div>
      <div>
        <img className={styles["promotion-img"]} src={informationImg} alt="" />
      </div>
    </div>
  );
};
export default InfoBar;
