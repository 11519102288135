import styles from "./BubbleHead.module.scss";

const BubbleHead = ({ src, alt, num, customClass = "" }) => {
  return (
    <div className={styles["head-container"]}>
      <img
        className={`${styles["head-img"]} ${customClass}`}
        src={src}
        alt={alt}
      />
      {num && <span className={styles["number-bubble"]}>{num}</span>}
    </div>
  );
};
export default BubbleHead;
