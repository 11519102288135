import styles from "./Table.module.scss";
import TableHeader from "./tableHeader/TableHeader";
import TableRow from "./tableRow/TableRow";

const Table = ({ headingList, tabeData, calculationAction }) => {
  return (
    <table className={styles["table-container"]}>
      <TableHeader headingList={headingList} />
      {tabeData &&
        tabeData.map((x, index) => {
          return (
            <TableRow
              key={index}
              index={index}
              rowItem={x}
              headingList={headingList}
              calculationAction={calculationAction}
            />
          );
        })}
    </table>
  );
};

export default Table;
