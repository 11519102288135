import getCurrentDateFormatted from "../../dateHelper/GetCurrentDateFormatted";
import discountList from "../discounts/DiscountList";
import orderTypes from "../order/OrderTypes";

const productList = [
  {
    id: "1",
    name: "Brufen FTBL 30x100mg",
    type: orderTypes[2],
    discount: undefined,
    unitPrice: 100,
    mainacturer: "Famar",
    fromDate: getCurrentDateFormatted(),
    toDate: getCurrentDateFormatted(2),
    exparationDate: getCurrentDateFormatted(20),
  },
  {
    id: "2",
    name: "IbuBrufen FTBL 30x100mg",
    type: orderTypes[2],
    discount: discountList[3],
    unitPrice: 100,
    mainacturer: "Galenika",
    fromDate: getCurrentDateFormatted(),
    toDate: getCurrentDateFormatted(5),
    exparationDate: getCurrentDateFormatted(20),
  },
  {
    id: "3",
    name: "Bensedin 1000mg",
    type: orderTypes[2],
    discount: discountList[1],
    unitPrice: 100,
    mainacturer: "Uraf",
    fromDate: getCurrentDateFormatted(),
    toDate: getCurrentDateFormatted(1),
    exparationDate: getCurrentDateFormatted(20),
  },
  {
    id: "4",
    name: "Kapi za nos",
    type: orderTypes[3],
    discount: discountList[3],
    unitPrice: 100,
    mainacturer: "Jugomedia",
    fromDate: getCurrentDateFormatted(),
    toDate: getCurrentDateFormatted(2),
    exparationDate: getCurrentDateFormatted(20),
  },
];
export default productList;
