import { useState } from "react";
import styles from "./NumericImput.module.scss";

const NumericImput = ({ data = 0, action }) => {
  const [numData, setNumData] = useState(data);

  const changeNumber = (i) => {
    const newNum = numData + i;
    if (newNum > -1) {
      setNumData(newNum);
      action && action(newNum);
    }
  };
  return (
    <div className={styles["numeric-input-container"]}>
      <span onClick={() => changeNumber(-1)}>-</span>
      {numData}
      <span onClick={() => changeNumber(1)}>+</span>
    </div>
  );
};
export default NumericImput;
