import styles from "./TableRow.module.scss";
import TableItem from "./tableItem/TableItem";
import calculateTotal from "./../../../helpers/functions/CalculateTotal";

const TableRow = ({ rowItem, headingList, index, calculationAction }) => {
  const tableRowStyles = `${styles["table-row"]} ${
    index % 2 > 0 ? styles["gray-background"] : ""
  }`;

  const calculationActionDown = (quantity) => {
    const newItem = {
      ...rowItem,
      Quantity: quantity,
      Total: calculateTotal(rowItem.UnitPrice, rowItem.Discount, quantity),
    };
    calculationAction(newItem);
  };

  return (
    <tr className={tableRowStyles}>
      {headingList.map((x) => {
        return (
          <TableItem
            data={rowItem[x.field]}
            itemType={x}
            calculationAction={calculationActionDown}
          />
        );
      })}
    </tr>
  );
};

export default TableRow;
