const Input = ({
  name = "",
  placeholder = "",
  value,
  type,
  action,
  customStyles = "",
}) => {
  return (
    <div className={customStyles}>
      {name && <label>{name}</label>}
      <div>
        <input
          placeholder={placeholder}
          type={type}
          value={value}
          onChange={(e) => {
            action(e.target.value);
          }}
        />
      </div>
    </div>
  );
};
export default Input;
