import styles from "./DownloadIcon.module.scss";
const icon = require("./../../../../../assets/UtilsIcons/preuzmi.png");

const Download = () => {
  return (
    <div className={styles["download-container"]}>
      <img src={icon} alt="" />
    </div>
  );
};
export default Download;
