import styles from "./SingleSelectDropdown.module.scss";
import { useState } from "react";

const SingleSelectDropdown = ({
  options = [],
  onSelect,
  defaultValue = undefined,
  placeholder = "Select an option",
  clearOptionText,
  isDisabled = false,
  label = "",
  customStyles = "",
}) => {
  const [selectedOption, setSelectedOption] = useState("none");

  const handleSelect = (e) => {
    const option = e.target.value;
    const newOption = options.find((x) => x.value == option);
    setSelectedOption(newOption ? newOption.value : "");
    onSelect(newOption);
  };
  return (
    <div className={customStyles}>
      <div>
        {label && (
          <label className="label" htmlFor="select">
            {label}
          </label>
        )}
      </div>
      <select
        disabled={isDisabled}
        value={selectedOption}
        onChange={handleSelect}
      >
        {selectedOption == "none" && <option value="">{placeholder}</option>}
        {/* {clearOptionText && <option value="">{clearOptionText}</option>} */}

        {options.map((option, index) => {
          return (
            <option key={index} value={option.value}>
              {option.value}
            </option>
          );
        })}
      </select>
    </div>
  );
};
export default SingleSelectDropdown;
