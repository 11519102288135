const orderInvoiceTableHeading = [
  { title: "Dokument", field: "id", type: "text" },
  { title: "Datum", field: "date", type: "text" },
  { title: "Status", field: "status", type: "text" },
  { title: "Kategorija", field: "type", type: "text" },
  { title: "Preuzmi", field: "id", type: "download" },
  { title: "Obriši", field: "id", type: "delete" },
];

export default orderInvoiceTableHeading;
