import styles from "./DonutChart.module.scss";

const DonutChart = ({ percent = 0.9, size = 150, strokeWidth = 15 }) => {
  const center = size / 2;
  const radoius = (size - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radoius;

  return (
    <div>
      <div className={styles["donut-chart-container"]}>
        <div className={styles["donut-svg"]}>
          <svg viewBox={`0 0 ${size} ${size}`}>
            <circle
              cy={center}
              cx={center}
              r={radoius}
              fill="white"
              stroke={"#ee7600"}
              strokeWidth={strokeWidth}
              strokeDasharray={circumference}
              strokeDashoffset={circumference * (1 - percent)}
            />
          </svg>
        </div>
        <div className={styles["donut-svg-label"]}>{percent * 100}%</div>
      </div>
    </div>
  );
};
export default DonutChart;
