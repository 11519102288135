import styles from "./DeleteIcon.module.scss";
const icon = require("./../../../../../assets/UtilsIcons/delete.png");

const DeleteIcon = () => {
  return (
    <div className={styles["download-container"]}>
      <img src={icon} alt="" />
    </div>
  );
};
export default DeleteIcon;
