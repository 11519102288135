import getCurrentDateFormatted from "../../dateHelper/GetCurrentDateFormatted";
import discountList from "../discounts/DiscountList";
import productList from "../product/ProductList";
import orderTypes from "./OrderTypes";
const orderList = [
  {
    Id: "154686",
    Product: productList[0],
    Name: "Brufen FTBL 30x100mg",
    UnitPrice: 100,
    DiscountType: discountList[0],
    Discount: "",
    Date: getCurrentDateFormatted(),
    type: orderTypes[2],
    Destination: "Address 1",
    Status: "Poslato",
    Quantity: 3,
    Total: 300,
  },
  {
    Id: "984384",
    Product: productList[1],
    Name: "IbuBrufen FTBL 30x100mg",
    Date: getCurrentDateFormatted(),
    DiscountType: discountList[5],
    Destination: "Address 2",
    type: orderTypes[2],
    Status: "Poslato",
    UnitPrice: 100,
    Quantity: 4,
    Discount: "3+1",
    Total: 300,
  },
  {
    Id: "944048",
    Product: productList[2],
    Name: "Bensedin 1000mg",
    Date: getCurrentDateFormatted(),
    DiscountType: discountList[2],
    Destination: "Address 1",
    type: orderTypes[2],
    Status: "Poslato",
    UnitPrice: 100,
    Quantity: 5,
    Discount: "10%",
    Total: 450,
  },
  {
    Id: "944049",
    Product: productList[3],
    Name: "Kapi za nos",
    Date: getCurrentDateFormatted(),
    DiscountType: discountList[1],
    Destination: "Address 3",
    type: orderTypes[3],
    Status: "Poslato",
    UnitPrice: 100,
    Quantity: 5,
    Discount: "10%",
    Total: 450,
  },
];
export default orderList;
