import styles from "./TableHeader.module.scss";

const TableHeader = ({ headingList }) => {
  return (
    <tr className={styles["table-header"]}>
      {headingList.map((x) => {
        return <th className={styles[""]}>{x.title}</th>;
      })}
    </tr>
  );
};
export default TableHeader;
